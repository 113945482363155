<script>
import BVForm from "@/components/BVForm/BVForm.vue";
import BVListForm from "@/components/BVForm/BVListForm.vue";
import renderSchema from '@/components/BVForm/RenderSchema.vue'
import Swal from "sweetalert2";
import { mapGetters } from 'vuex'

export default {
  components: {
    BVForm,
    BVListForm,
    renderSchema
  },
  props: {
    vehiculo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      allData: null,
      isVisibleNuevoRegistro: false,
      titleNuevoRegistro: 'Nuevo registro',
      isVisibleHistorial: false,
      historial: [],
      ultimoRegistroActivo: null,

      subForm: null,
      todosLosComparendos: []

    }
  },
  async mounted() {

    this.allData = JSON.parse(JSON.stringify(await this.$store.dispatch('getHojaDeVidaVehiculoIncidentesYAccidentes', this.empresaId)))
    await this.getHistorial()
    this.ajustesDeLaVisata()

    this.subForm = JSON.parse(JSON.stringify(await this.$store.dispatch('getHojaDeVidaVehiculoComparendo')))
  
    this.comparendosPorVehiculo()
  },
  computed: {
    ...mapGetters(['useMoment']),
    empresaId() {
      return this.$store.getters.userLoggedIn.empresa.id
    }
  },
  methods: {
    async getHistorial() {
      const response = await this.$store.getters.fetchGet({ path: `VehiculoIncidentesYAccidentes/ListaPorVehiculo/${this.vehiculo.id}` })
      this.historial = await response.json()
       // reverse
       this.historial = this.historial.reverse()
    },
    ajustesDeLaVisata() {
      const last = this.historial.reduce((prev, current) => {
        return (prev.fechaDeCreacion > current.fechaDeCreacion) ? prev : current
      })
      if (last.estaCerrado == 0) {
        this.ultimoRegistroActivo = last
        this.titleNuevoRegistro = 'Editar registro'
        this.allData.forEach(item => {
          if (item.type == 'date' && last[item.name] != null) {
            last[item.name] = last[item.name].split('T')[0]
          }
          item.value = last[item.name]
        })
        this.isVisibleNuevoRegistro = true
      } else {
        this.isVisibleNuevoRegistro = !this.historial.length
        this.isVisibleHistorial = true
      }
    },
    cerrarRegistro() {
      // pregunta y advierte si desea cerrar el registro una vez realizado no se podra volver a editar
      Swal.fire({
        title: '¿Está seguro de cerrar el registro?',
        text: "Una vez cerrado no se podrá volver a modificar",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',

        confirmButtonText: 'Cerrar registro'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.getters.fetchPut({ path: `VehiculoIncidentesYAccidentes/cerrarRegistro/${this.ultimoRegistroActivo.id}` })
            .then(response => {
              if (response.ok) {
                return response.json()
              }
              throw new Error('Error al cerrar el registro')
            })
            .then(async () => {
              await this.getHistorial()

              this.ultimoRegistroActivo = null
              this.titleNuevoRegistro = 'Nuevo registro'
              this.allData.forEach(item => {
                item.value = null
              })

              Swal.fire(
                'Registro cerrado',
                'El registro ha sido cerrado',
                'success'
              )
            })
            .catch(error => {
              Swal.fire({
                icon: 'error',
                title: 'Error al cerrar el registro',
                text: error.message,
                showConfirmButton: true,
              })
            })
        }
      })
    },

    callbackOnSubmit(self) {
      let data = { ...self.data }
      data.vehiculoId = this.vehiculo.id

      if (this.ultimoRegistroActivo) {
        const { id } = this.ultimoRegistroActivo

        self.loader = true
        this.$store.getters.fetchPut({ path: `VehiculoIncidentesYAccidentes/${id}`, data })
          .then(response => {
            if (response.ok) {
              return response.json()
            }
            throw new Error('Error al actualizar')
          })
          .then(async () => {
            await this.getHistorial()
            this.ajustesDeLaVisata()
            Swal.fire({
              icon: 'success',
              title: 'Registro actualizado',
              showConfirmButton: true,
            })
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error al actualizar',
              text: error.message,
              showConfirmButton: true,
            })
          })
          .finally(() => {
            self.loader = false
          })

      }
      else {
        self.loader = true
        this.$store.getters.fetchPost({ path: `VehiculoIncidentesYAccidentes`, data })
          .then(response => {
            if (response.ok) {
              return response.json()
            }
            throw new Error('Error al crear el registro')
          })
          .then(async () => {
            await this.getHistorial()
            this.ajustesDeLaVisata()
            Swal.fire({
              icon: 'success',
              title: 'Registro creado',
              showConfirmButton: true,
            })
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Error al crear el registro',
              text: error.message,
              showConfirmButton: true,
            })
          })
          .finally(() => {
            self.loader = false
          })
      }

    },

    async setComparendos(self) {
      const response = await this.$store.getters.fetchGet({ path: `VehiculoComparendo/ListaPorIncidenteYAccidente/${this.ultimoRegistroActivo.id}` })
      const data = await response.json()


      for (let i = 0; i < data.length - self.oneDefault; i++) {
        self.pushNewForm()
      }


      for (let i = 0; i < data.length; i++) {
        self.forms[i].forEach(item => {
          item.value = data[i][item.name]
        })
      }

    },
  
    saveComparendos(self) {

      self.data.forEach(element => {
        element.VehiculoId = this.vehiculo.id,
          element.IncidentesAccidentesId = this.ultimoRegistroActivo.id
      })

      const path = `VehiculoComparendo/Multiple/${this.ultimoRegistroActivo.id}`
      self.loader = true
      this.$store.getters.fetchPost({ path, data: self.data })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Error al guardar los comparendos')
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Guardado',
            text: 'Se ha guardado correctamente',
            showConfirmButton: true
          })
        })
        .catch(error => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: error.message,
            showConfirmButton: true
          })
        })
        .finally(() => {
          self.loader = false
        })

    },

    async comparendosPorVehiculo() {
      const response = await this.$store.getters.fetchGet({ path: `VehiculoComparendo/ComparendosPorVehiculo/${this.vehiculo.id}` })
      this.todosLosComparendos = await response.json()      
    },


    getComparendoDelIncidente( incidenteYAccidenteId ) {
      return this.todosLosComparendos.filter( comparendo => comparendo.incidentesAccidentesId == incidenteYAccidenteId )
    }


  }
}

</script>

<template>
  <div>
    <div>
      <div @click="isVisibleNuevoRegistro = !isVisibleNuevoRegistro" class="pointer">
        <h3 class="py-2 btn btn-outline-success px-5 my-1">{{ titleNuevoRegistro }}</h3>
      </div>
      <b-collapse id="nuevoRegistro" v-model="isVisibleNuevoRegistro">
        <BVForm title="Incidentes y accidentes" :schema="allData" :callBackSubmit="callbackOnSubmit">
          <template #otherBtns>
            <button v-if="ultimoRegistroActivo" @click.prevent="cerrarRegistro" class="btn btn-outline-warning">
              Cerrar registro
            </button>
          </template>
        </BVForm>

        <b-list-group>
          <b-list-group-item v-if="!ultimoRegistroActivo">
            <p class="text-center">Para proceder con la emisión de un <strong>comparendo</strong>, es necesario completar
              inicialmente el formulario correspondiente a incidentes y accidentes.</p>
          </b-list-group-item>
          <b-list-group-item v-if="subForm && ultimoRegistroActivo">
            <BVListForm title="Comparendo asociado" :baseSchema="subForm" oneDefault :callBackInit="setComparendos"
              :callBackSubmit="saveComparendos">
              <template #text-guardar>Guardar comparendo</template>
              <template #text-nuevo-registro>Nuevo comparendo</template>
            </BVListForm>
          </b-list-group-item>
        </b-list-group>


      </b-collapse>
    </div>
    <div>
      <div @click="isVisibleHistorial = !isVisibleHistorial" class="pointer">
        <h3 class="py-2 btn btn-outline-success px-5 my-1">Historial</h3>
      </div>
      <div>
        <b-collapse id="historial" v-model="isVisibleHistorial">
          <renderSchema class="mb-5" v-for="registro in historial" :key="registro.id" :schema="allData" :data="registro">
            <template #title>
              <h4 class="mt-4 mb-3">Registro del {{ useMoment(registro.fechaDeCreacion) }}</h4>
            </template>
            <template #footer>
              <renderSchema 
              v-for="(comparendo, idx) in getComparendoDelIncidente( registro.id )" :key="comparendo.id"
              :schema="subForm" :data="comparendo">
                <template #title>
                  <h6 class="mt-4 mb-3">Comparendo {{ idx + 1 }}</h6>
                </template>
              </renderSchema>
            </template>
          </renderSchema>
        </b-collapse>
      </div>
    </div>

  </div>
</template>

<style scoped>
.btn btn-outline-success px-5 my-1 {
  border-bottom: .5px solid #dee2e6;
}
</style>